// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { AuthorizationFetchPlanDto } from './dto/fetch-plan.dto';
import { AuthorizationFetchLastTimeOrderInfoDto } from './dto/last-time-order-info.dto';
import { AuthorizationOrderDto } from './dto/order.dto';

export class AuthorizationModel {
  static fetchPlans(): Promise<AuthorizationFetchPlanDto[]> {
    return wsc.execute('/ws/authorization/fetchPlans') as any;
  }

  static fetchLastTimeOrderInfo(): Promise<AuthorizationFetchLastTimeOrderInfoDto> {
    return wsc.execute('/ws/authorization/fetchLastTimeOrderInfo') as any;
  }

  static order(orderInfo: AuthorizationOrderDto): Promise<{
    action: string;
    form: {
      [key: string]: string;
    };
  }> {
    return wsc.execute('/ws/authorization/order', orderInfo) as any;
  }

  static fetchContractStatistic(): Promise<any> {
    return wsc.execute('/ws/authorization/fetchContractStatistic') as any;
  }

  static fetchLastContract(): Promise<any> {
    return wsc.execute('/ws/authorization/fetchLastContract') as any;
  }

  static fetchContract(
    year: number,
    page: any = 1,
    pageSize: any = 30,
    order: 'ASC' | 'DESC' = 'ASC',
  ): Promise<any> {
    return wsc.execute(
      '/ws/authorization/fetchContract',
      year,
      page,
      pageSize,
      order,
    ) as any;
  }

  static fetchCoupon(
    couponCode: string,
    planCode: string,
  ): Promise<{
    code: string;
    startDate: string;
    expireDate: string;
    type: string;
    count: number;
    discount: number;
    planCode: string;
  }> {
    return wsc.execute(
      '/ws/authorization/fetchCoupon',
      couponCode,
      planCode,
    ) as any;
  }
}
// 08b4fbe6a9d216489612d0519a16810c9fb0f0a45b6b3192d1a0b7d846f7cb7a
