<div class="conditions">
  <div
    class="condition"
    fxLayout="row"
    fxLayoutGap="15px"
    *ngFor="let condition of node.conditions; let index = index"
  >
    <div class="no">{{ index + 1 }}.</div>

    <mat-form-field class="name" fxFlex="2 0 0" legacyAppearance>
      <mat-label i18n>情況</mat-label>
      <input matInput [(ngModel)]="condition.name" />
    </mat-form-field>

    <mat-form-field class="weight" fxFlex="2 0 0" legacyAppearance>
      <mat-label i18n>權重</mat-label>
      <input matInput [(ngModel)]="condition.weight" />
    </mat-form-field>

    <mat-form-field
      legacyAppearance
      class="percentage"
      matTooltip="{{ condition.weight }} / {{ sum }}"
    >
      <mat-label i18n>機率</mat-label>
      <input
        matInput
        [ngModel]="
          sum
            ? ((parseInt(condition.weight, 10) / sum) * 100 | number: '0.0-2') +
              '%'
            : '-'
        "
        disabled
      />
    </mat-form-field>

    <div class="tools">
      <div class="color-button-wrap">
        <button
          class="color"
          #button
          mat-button
          [style.background-color]="condition.color"
          (click)="openColorPicker(button._elementRef, condition)"
        ></button>
      </div>

      <button (click)="setOrderCondition(index, -1)" mat-icon-button>
        <mat-icon>arrow_upward</mat-icon>
      </button>

      <button (click)="setOrderCondition(index, 1)" mat-icon-button>
        <mat-icon>arrow_downward</mat-icon>
      </button>

      <button
        mat-icon-button
        (click)="deleteCondition(index)"
        matTooltip="刪除此情況"
        i18n-matTooltip="刪除此情況"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</div>

<ng-template #footer>
  <button (click)="addCondition()" mat-button i18n>
    <mat-icon>add</mat-icon>新增隨機情況
  </button>
  <button mat-button (click)="dynamic.submit()" i18n>確定</button>
</ng-template>
