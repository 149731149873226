import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  AsyncPipe,
  NgFor,
  NgIf,
  NgSwitch,
  NgSwitchCase,
} from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import {
  MatChipGrid,
  MatChipInput,
  MatChipInputEvent,
  MatChipRemove,
  MatChipRow,
} from '@angular/material/chips';
import { MatOption } from '@angular/material/core';
import {
  MatFormField,
  MatLabel,
  MatSuffix,
} from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import {
  PropertyConfigDataType,
  PropertyConfigDto,
} from '@ay-gosu/server-shared';
import { BehaviorSubject, Subject, combineLatest, firstValueFrom } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { SetterNode } from '..';
import { GosuValidatorComponent } from '../../../../../components/gosu-validator/gosu-validator.component';
import { ProgrammableComponent } from '../../../../../components/programmable/programmable.component';
import { TagTreePickerComponent } from '../../../../../components/tag-tree-picker/tag-tree-picker.component';
import { LegacyAppearanceDirective } from '../../../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { MatTooltip } from '../../../../../material/tooltip/tooltip';
import { SplitPipe } from '../../../../../pipe/split.pipe';
import { PropertyConfigService } from '../../../../../service/property-config.service';
import { TagService } from '../../../../../service/tag.service';
import { FlowService } from '../../../flow.service';
import { Command, CommandAction, CommandItem, Target } from '../class';

@Component({
  selector: 'flow-setter-node-command',
  templateUrl: './command.component.html',
  styleUrls: ['./command.component.scss'],
  standalone: true,
  imports: [
    FlexModule,
    NgSwitch,
    MatFormField,
    MatLabel,
    MatSelect,
    FormsModule,
    NgIf,
    MatOption,
    NgSwitchCase,
    ProgrammableComponent,
    TagTreePickerComponent,
    GosuValidatorComponent,
    MatSelectTrigger,
    MatInput,
    MatIcon,
    MatSuffix,
    NgFor,
    MatChipGrid,
    MatChipRow,
    MatChipRemove,
    MatChipInput,
    MatIconButton,
    MatTooltip,
    AsyncPipe,
    SplitPipe,
    LegacyAppearanceDirective,
  ],
})
export class CommandComponent implements OnDestroy, AfterViewInit {
  public readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  public keyword$ = new BehaviorSubject('');

  public noneExistTag = $localize`此標籤已刪除`;

  public deletedTagIds: number[] = [];

  public deletedTagIds$ = this._tagService.profile.showAllWithDeleted.list$
    .pipe(
      map((tags) => tags.filter((tag) => tag.deletedAt).map((tag) => tag.id)),
    )
    .subscribe((tags) => (this.deletedTagIds = tags));

  @Input()
  public no: number;

  @Input()
  public command: Command;

  @Input()
  public node: SetterNode;

  //#region @Two-way() target
  private _target: Target = 'profile';

  public target$ = new BehaviorSubject<Target>('profile');

  public get target(): Target {
    return this._target;
  }

  @Input()
  public set target(target: Target) {
    if (this._target === target) return;
    this._target = target;
    this.target$.next(this._target);
    this.checkError();
  }
  //#endregion @Two-way() target

  @Input() public checkError: Function;

  public hasError: boolean = false;

  public ngOnInit() {
    this.findDuplicateProperties();
  }

  public ngAfterViewInit() {
    this.checkError();
  }

  public configs$ = combineLatest(
    this.target$,
    this._propertyConfigService.all$,
    this.keyword$,
  ).pipe(
    map(([target, configs, keyword]) => {
      if (!configs) return;
      return configs.filter(
        (config) =>
          config.targetType === target &&
          (config?.name?.includes(keyword) || config?.key?.includes(keyword)),
      );
    }),
  );

  public config: PropertyConfigDto;

  private readonly _destroy$ = new Subject<void>();

  private _whenConfigs$ChangedLinkConfig = this.configs$
    .pipe(takeUntil(this._destroy$))
    .subscribe((configs) => {
      this.afterPropertyChange();
    });

  @Output()
  public delete = new EventEmitter<void>();

  public constructor(
    public flow: FlowService,
    private readonly _tagService: TagService,
    private _propertyConfigService: PropertyConfigService,
  ) {}

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public async afterPropertyChange() {
    const configs = await firstValueFrom(this.configs$);
    this.config = configs.find((config) => config.key === this.command.key);
    this._autoFixAction();
    this.findDuplicateProperties();
    this.checkError();
  }

  public afterItemChange() {
    this._autoFixAction();
  }

  private _autoFixAction() {
    const enabled = this._getEnabledActionFromItem(this.command.item);
    if (!enabled.includes(this.command.action)) {
      this.command.action = enabled[0];
    }

    if (this.command.item === 'property' && this.config != null) {
      const enabled = this._getEnabledActionFromDataType(this.config.dataType);
      if (!enabled.includes(this.command.action)) {
        this.command.action = enabled[0];
      }
    }
  }

  private _getEnabledActionFromDataType(
    dataType: PropertyConfigDataType,
  ): CommandAction[] {
    switch (dataType) {
      case 'number':
        return ['set', 'plus', 'minus', 'clear'];

      case 'list':
        return ['set', 'add', 'remove', 'clear'];

      case 'string':
      default:
        return ['set', 'clear'];
    }
  }

  private _getEnabledActionFromItem(item: CommandItem): CommandAction[] {
    switch (item) {
      case 'tag':
        return ['add', 'remove'];

      case 'property':
      default:
        return ['set', 'plus', 'minus', 'add', 'remove', 'clear'];
    }
  }

  public selected(select: MatOption | MatOption[]) {
    if (!(select instanceof MatOption)) return;
    return select?.viewValue;
  }

  public addText(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    const control = this.command.value ? this.command.value.split(',') : [];

    if ((value || '').trim()) {
      control.push(value.trim());
      this.command.value = control.join(',');
    }

    if (input) {
      input.value = '';
    }
  }

  public removeText(value: string) {
    const index = this.command.value.split(',').indexOf(value);
    const control = this.command.value ? this.command.value.split(',') : [];

    if (index >= 0) {
      control.splice(index, 1);
      this.command.value = control.join(',');
    }
  }

  public findDuplicateProperties() {
    if (!this.node) return;
    this.duplicateProperties = this.node.commands
      .map((command) => command.key)
      .filter((key, index, keys) => keys.indexOf(key) !== index);
    this.duplicatePropertiesChange$.next();
    this.seletedPropertyConfigs = this.node.commands.map(
      (command) => command.key,
    );
  }

  public duplicateProperties = [];

  public duplicatePropertiesText = $localize`屬性重複`;

  public duplicatePropertiesChange$ = new BehaviorSubject<void>(null);

  public seletedPropertyConfigs = [];
}
