import { TagDto } from '@ay-gosu/server-shared';
import { RuleOp } from '@ay-gosu/types';
import { ensureTheElementIsWhitelisted } from '@ay/util';
import { firstValueFrom } from 'rxjs';
import { first } from 'rxjs/operators';
import { ReadableError } from '../../../../../util/readable-error';
import { FromJsonOption, Rule } from '../rule';
import { GosuValidate } from '../../../../components/gosu-validator/gosu-validator.class';

export class TagRule extends Rule {
  public static op: RuleOp[] = ['HAVE', 'NOT'];

  public constructor(tag: TagDto) {
    super('TAG');
    this.tag = tag;
    this.op = 'HAVE';
  }

  public afterTypeChanged(isUserEvent = false) {
    this.op = ensureTheElementIsWhitelisted(this.op, ...TagRule.op);
    if (isUserEvent && this.component && this.component.tag) {
      this.component.tag.opTrigger.openMenu();
    }
  }

  public async fromJSON(json: any, option: FromJsonOption) {
    await super.fromJSON(json, option);

    const tagId = json.tagId;

    const tags = await firstValueFrom(
      option.tagService.all$.pipe(first((tags) => tags !== null)),
    );

    this.tag = TagRule.findTagById(tags, tagId);
  }

  public toRuleObject(json: any = {}) {
    super.toRuleObject(json);
    json.tagId = this.tag ? this.tag.id : null;
    return json;
  }

  private static findTagById(tags: TagDto[], id: number) {
    return (
      tags.find((tag) => tag.id === id) ||
      tags
        .filter((tag) => tag.children && tag.children.length)
        .reduce(
          (prev, tag) => prev.concat(TagRule.findTagById(tag.children, id)),
          [],
        )[0]
    );
  }

  public similar(keyword: string) {
    return this.tag.name.toLowerCase().indexOf(keyword) !== -1;
  }

  public toString() {
    if (this.tag === undefined) {
      switch (this.op) {
        case 'HAVE':
          return `未知的標籤`;
        case 'NOT':
          return `沒有未知的標籤`;
      }
    }

    switch (this.op) {
      case 'HAVE':
        return $localize`有'${this.tag.name}'標籤`;
      case 'NOT':
        return $localize`沒有'${this.tag.name}'標籤`;
    }
  }

  public static schema: string = 'tag';

  public toShortCode(): string {
    return `${this.tag.id}`;
  }

  public static async fromShortCode(code: string, option: FromJsonOption) {
    const tagId = parseInt(code);

    const tags = await firstValueFrom(
      option.tagService.all$.pipe(first((tags) => tags !== null)),
    );

    const tag = tags.find((tag) => tag.id === tagId);

    if (tag === undefined) {
      throw new ReadableError($localize`未知的標籤編號${code}`);
    }

    return new TagRule(tag);
  }

  public checkError(): boolean {
    if (GosuValidate.required(this.tag) || this.tag.deletedAt) {
      return true;
    }
    return false;
  }
}
