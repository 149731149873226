import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'gosu-redirect-page-dialog',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDialogModule, RouterLink],
  template: `
    <div class="min-w-[150px] max-w-[500px] gap-2 p-6">
      <div class="mb-3 text-lg font-medium">
        {{ data.title }}
      </div>

      <div class="mb-6">
        {{ data.content }}
      </div>

      <div class="mt-7 flex justify-end gap-2">
        <button i18n mat-button (click)="close()">關閉</button>
        <button
          mat-button
          color="primary"
          [routerLink]="data.redirectLink"
          (click)="dialogRef.close(true)"
        >
          {{ data.buttonText }}
        </button>
      </div>
    </div>
  `,
})
export class RedirectPageDialog {
  constructor(
    public dialogRef: MatDialogRef<RedirectPageDialog>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title?: string;
      content?: string;
      buttonText?: string;
      redirectLink?: string;
    },
  ) {}

  close(): void {
    this.dialogRef.close(false);
  }
}
