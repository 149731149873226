// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { FetchDefaultImageDto } from './dto/fetch-default-image.dto';
import { FetchInteractiveListResponseDto } from './dto/fetch-interactive-list-response.dto';
import { FetchInteractiveNotificationPackageIdDto } from './dto/fetch-interactive-notification-package-id.dto';
import { FetchQualificationLogsResponseDto } from './dto/fetch-qualification-logs-response.dto';
import { InteractiveDtoSet } from './dto/interactive-dto-set.type';
import { InteractiveStatus } from './dto/interactive-status.type';
import { InteractiveType } from './dto/interactive-type.type';

export class InteractiveModel {
  // 讀取任務抽獎遊戲列表
  static list(
    filter: {
      keyword?: string;
      type?: InteractiveType;
      status?: InteractiveStatus;
      approved?: boolean;
    },
    paging: {
      page: number;
      size: number;
    },
  ): Promise<FetchInteractiveListResponseDto> {
    return wsc.execute('/ws/interactive/list', filter, paging) as any;
  }

  // 讀取任務抽獎遊戲
  static fetch(id: number): Promise<InteractiveDtoSet> {
    return wsc.execute('/ws/interactive/fetch', id) as any;
  }

  // 建立任務抽獎遊戲
  static createInteractive(
    body: InteractiveDtoSet,
  ): Promise<InteractiveDtoSet> {
    return wsc.execute('/ws/interactive/createInteractive', body) as any;
  }

  // 更新任務抽獎遊戲
  static updateInteractive(id: number, body: InteractiveDtoSet): Promise<void> {
    return wsc.execute('/ws/interactive/updateInteractive', id, body) as any;
  }

  // 刪除任務抽獎遊戲
  static deleteInteractive(id: number): Promise<void> {
    return wsc.execute('/ws/interactive/deleteInteractive', id) as any;
  }

  static fetchInteractiveNotificationPackageId(
    id: number,
  ): Promise<FetchInteractiveNotificationPackageIdDto> {
    return wsc.execute(
      '/ws/interactive/fetchInteractiveNotificationPackageId',
      id,
    ) as any;
  }

  static changeApprove(id: number, isApproved: boolean): Promise<void> {
    return wsc.execute('/ws/interactive/changeApprove', id, isApproved) as any;
  }

  static fetchDefaultImage(): Promise<FetchDefaultImageDto> {
    return wsc.execute('/ws/interactive/fetchDefaultImage') as any;
  }

  static fetchPreviewUrl(id: number, type: InteractiveType): Promise<any> {
    return wsc.execute('/ws/interactive/fetchPreviewUrl', id, type) as any;
  }

  static downloadReport(
    interactiveId: number,
  ): Promise<{ fileName: string; buffer: Buffer }> {
    return wsc.execute('/ws/interactive/downloadReport', interactiveId) as any;
  }

  static fetchQualificationLogs(
    interactiveModuleId: number,
    profileData: string,
  ): Promise<FetchQualificationLogsResponseDto[]> {
    return wsc.execute(
      '/ws/interactive/fetchQualificationLogs',
      interactiveModuleId,
      profileData,
    ) as any;
  }

  static copyInteractiveById(interactiveModuleId: number): Promise<void> {
    return wsc.execute(
      '/ws/interactive/copyInteractiveById',
      interactiveModuleId,
    ) as any;
  }
}
// 8fa308e009b185787a7baa1f7205bf2e3f05ebc54a10a6ba3a33ab0338764a98
