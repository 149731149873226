import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TemplateModel } from '@ay-gosu/server-shared';
import { Content } from '@ay/bot';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessageAffectedComponent } from '../../message-affected.component';
import { Template } from '../../template.class';
import { MatProgressBar } from '@angular/material/progress-bar';

const DEBOUNCE = 2000;
const PERCENTAGE = 100;

@Component({
  selector: 'ms-template-preview',
  templateUrl: './template-preview.component.html',
  styleUrls: ['./template-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: ['message'],
  standalone: true,
  imports: [MatProgressBar],
})
export class TemplatePreviewComponent
  extends MessageAffectedComponent
  implements OnInit, OnDestroy
{
  public countdown = 0;
  public timer = null;

  //#region propertiesChangeNotifier
  private _notifier: Subject<any> = null;

  public get notifier(): Subject<any> {
    return this._notifier;
  }

  @Input()
  public set notifier(notifier: Subject<any>) {
    if (this._notifier === notifier) return;
    this._notifier = notifier;
    this.subscribe();
  }
  //#endregion propertiesChangeNotifier

  @Input()
  public template: Template;

  @Output()
  public afterTemplateExec = new EventEmitter<Content.Base>();

  protected _subscription: Subscription;

  public constructor(protected readonly changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  public ngOnInit() {
    this.subscribe();
  }

  public ngOnDestroy() {
    this.destroy$.complete();
  }

  protected subscribe() {
    if (this._subscription) {
      this.unsubscribe();
    }

    this._subscription = this.notifier
      .pipe(takeUntil(this.destroy$))
      .subscribe((e) => this.afterPropertyChange());
  }

  protected unsubscribe() {
    if (!this._subscription.closed) {
      this._subscription.unsubscribe();
    }
  }

  public afterPropertyChange() {
    this.countdown = PERCENTAGE;
    this.changeDetectorRef.markForCheck();
    this.nextTick();
  }

  public countdownTick() {
    this.timer = null;
    if (this.countdown) {
      this.countdown--;
      this.changeDetectorRef.markForCheck();
      this.nextTick();
    } else {
      this.afterDebounce();
    }
  }

  public nextTick() {
    if (!this.timer) {
      this.timer = setTimeout(
        () => this.countdownTick(),
        DEBOUNCE / PERCENTAGE,
      );
    }
  }

  public async afterDebounce() {
    let properties = {};
    this.template.properties.forEach((property) => {
      properties[property.key] = property.default;
    });
    this.template.loading = true;
    this.message.changed();

    let id = this.template.templateId;
    let result = await TemplateModel.exec(id, properties, false, null);
    this.afterTemplateExec.next(result);
    this.template.loading = false;
    this.message.changed();
  }
}
