// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import {
  ListTicketEventResponseDto,
  ListTicketResponseDto,
} from '@ay-gosu/types';
import { CreateTicketEventResponseDto } from './dto/create-ticket-event-response.dto';
import { CreateTicketEventDto } from './dto/create-ticket-event.dto';
import { UpdateTicketEventBodyDto } from './dto/update-ticket-event.dto';

export class TicketEventModel {
  // 取得獎品包清單
  static list(): Promise<ListTicketEventResponseDto[]> {
    return wsc.execute('/ws/ticket-event/list') as any;
  }

  // 新增獎品包
  static create(
    data: CreateTicketEventDto,
  ): Promise<CreateTicketEventResponseDto> {
    return wsc.execute('/ws/ticket-event/create', data) as any;
  }

  // 更新獎品包
  static update(
    ticketEventId: number,
    data: UpdateTicketEventBodyDto,
  ): Promise<void> {
    return wsc.execute('/ws/ticket-event/update', ticketEventId, data) as any;
  }

  // 刪除獎品包
  static delete(ticketEventId: number): Promise<void> {
    return wsc.execute('/ws/ticket-event/delete', ticketEventId) as any;
  }

  // 列出獎品包下的獎品清單
  static listTickets(ticketEventId: number): Promise<ListTicketResponseDto[]> {
    return wsc.execute('/ws/ticket-event/listTickets', ticketEventId) as any;
  }

  static listInteractiveTickets(
    ticketEventId: number,
    interactiveModuleId: number = null,
  ): Promise<ListTicketResponseDto[]> {
    return wsc.execute(
      '/ws/ticket-event/listInteractiveTickets',
      ticketEventId,
      interactiveModuleId,
    ) as any;
  }
}
// 016f9fa33c553b70cc02d7d59db83f0f4d5568f0d7efa133d415427ead67be18
