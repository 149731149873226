import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import * as Sentry from '@sentry/angular-ivy';
import { BrowserTracing } from '@sentry/tracing';
import { addExceptionMechanism } from '@sentry/utils';
import { firstValueFrom } from 'rxjs';
import { environment } from '../environments/environment';
import { version } from '../environments/version';
import { ErrorDialog } from './dialog/basic';

@Injectable({
  providedIn: 'root',
})
export class GosuErrorHandler implements ErrorHandler {
  protected enableSentry: boolean;
  protected isFirstTime: boolean = true;

  public constructor(
    private readonly _ngZone: NgZone,
    private readonly _matConnectedDialog: MatConnectedDialog,
    private readonly _router: Router,
  ) {
    this.initSentry();
  }

  public initSentry() {
    if (!environment.sentry) {
      return;
    }

    this.enableSentry = true;

    Sentry.init({
      dsn: environment.sentry,
      environment: environment.ENV,
      release: version,
      integrations: [
        new BrowserTracing({
          tracingOrigins: ['localhost', 'https://api.gosu.bar'],
          routingInstrumentation: Sentry.routingInstrumentation as any,
        }),
      ] as any,

      tracesSampleRate: 1.0,
    });
  }

  public ignoreErrors = [
    "Error: Cannot read property 'getText' of null",
    'EmptyResponseError',
    'ExpressionChangedAfterItHasBeenCheckedError',
    "TypeError: Cannot read property 'focusInitialElementWhenReady' of undefined",
    "TypeError: Cannot read property 'focusInitialElement' of undefined",
    "Cannot read property 'getText' of null",
    'EmptyResponseError',
    'ExpressionChangedAfterItHasBeenCheckedError',
    "Could not find source file: 'inmemory://model/1'.",
  ];

  public silenceErrors = ["Cannot read property 'messages' of null"];

  public async handleError(error: any) {
    console.error(error);
    // 如果錯誤是 UNAUTHORIZED，且不是在登入頁面，則自動登出
    if (error.code === 'UNAUTHORIZED') {
      if (!this._router.url.includes('/login')) {
        this._router.navigateByUrl('/login/logout');
      }
      return;
    }

    const errorMessage = error?.message ?? error;

    if (typeof errorMessage === 'string') {
      if (this.ignoreErrors.find((ignore) => errorMessage.includes(ignore))) {
        console.error('ignore error', error);
        return;
      }
    }

    if (
      errorMessage ===
      'Uncaught (in promise): Object: {"message":"Unauthorized","arg":null,"error":{}}'
    ) {
      return this._showUnauthorizedErrorDialog();
    }

    let eventId = '';

    if (this.enableSentry) {
      eventId = this._ngZone.runOutsideAngular(() =>
        Sentry.captureException(error, (scope) => {
          scope.addEventProcessor((event: any) => {
            addExceptionMechanism(event, {
              type: 'angular',
              handled: false,
            });

            return event;
          });

          return scope;
        }),
      );
    }

    if (this.silenceErrors.find((silence) => errorMessage.includes(silence))) {
      return;
    }

    if (!this.isFirstTime) {
      return;
    }

    this.isFirstTime = false;

    const dialogRef = this._matConnectedDialog.open(ErrorDialog, {
      disableClose: true,
    });

    const component = dialogRef.componentInstance;
    if (eventId) {
      component.content = $localize`哎呀！系統發生了一些錯誤！<br/>
      請嘗試重新整理，或是聯絡客服並提供錯誤追蹤碼<br/>
      錯誤追蹤碼：<span class='highlight'>${eventId}</span>`;
    } else {
      component.content = $localize`哎呀！ 發生了一些錯誤，請聯絡客服人員請求協助。`;
    }

    // 超時錯誤
    if (error.code === 'TIMEOUT') {
      component.content = $localize`請求逾時，請稍後再試`;
    }

    component.buttons = [
      {
        label: $localize`等待`,
        result: 'WAIT',
      },
      {
        label: $localize`重新整理`,
        type: 'raised',
        result: 'REFRESH',
        color: 'primary',
      },
    ];

    const response = await firstValueFrom(dialogRef.afterClosed());

    switch (response) {
      case 'WAIT':
        break;

      case 'REFRESH':
        window.location.href = '/';
        break;
    }
  }

  private async _showUnauthorizedErrorDialog() {
    const ref = this._matConnectedDialog.open(ErrorDialog, {
      disableClose: true,
    });
    const dialog = ref.componentInstance;
    dialog.content = $localize`登入狀態異常，請重新登入`;
    dialog.buttons = [
      {
        label: $localize`重新登入`,
        type: 'raised',
        color: 'primary',
        result: 'LOGOUT',
      },
    ];
    await firstValueFrom(ref.afterClosed());
    this._router.navigateByUrl('/login/logout');
    return;
  }
}
