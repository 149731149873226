<button
  class="menu"
  mat-icon-button
  (click)="iconClick.emit($event)"
  *ngIf="(logged$ | async) && !!(contract$ | async)"
>
  <mat-icon>menu</mat-icon>
</button>

<gosu-logo [size]="164" theme="primary"></gosu-logo>

<div class="blank"></div>

<ng-container *ngIf="announcement$ | async as announcement">
  <ng-container *ngIf="announcement.link; else notLink">
    <a
      class="announcement"
      [style.backgroundColor]="announcement.background_color"
      [style.color]="announcement.font_color"
      [href]="announcement.link || '#'"
      target="_new"
    >
      <ng-container
        *ngTemplateOutlet="inner; context: { $implicit: announcement }"
      >
      </ng-container>
    </a>
  </ng-container>

  <ng-template #notLink>
    <div
      class="announcement"
      [style.backgroundColor]="announcement.background_color"
      [style.color]="announcement.font_color"
      [matTooltip]="announcement.title"
    >
      <ng-container
        *ngTemplateOutlet="inner; context: { $implicit: announcement }"
      >
      </ng-container>
    </div>
  </ng-template>
</ng-container>

<div class="blank"></div>

@if (konamiCode.unlock$ | async) {
  <div class="log-button-wrap">
    <button
      class="log-button"
      color="primary"
      mat-flat-button
      routerLink="/log"
    >
      <mat-icon>find_in_page</mat-icon>
      查詢 LOG
    </button>
  </div>
}

<div class="payment-button-wrap" *ngIf="environment.features.payment">
  <button
    class="payment-button"
    mat-flat-button
    color="primary"
    routerLink="/payment"
    i18n
  >
    <mat-icon>shopping_cart</mat-icon>
    前往購買
  </button>
</div>

<ng-container *asyncJob="tokenService.company$; let company">
  <div class="company-button-wrap">
    <button
      class="company-button"
      mat-button
      [matMenuTriggerFor]="companyMenu"
      #companyMenuTrigger="matMenuTrigger"
      fxLayout="row"
      fxLayoutAlign="center center"
      [class.no-contract]="(contract$ | async) === null"
    >
      <div class="name" fxFlex="1 0 0">{{ company.name }}</div>
      <div class="icon-wrap">
        <mat-icon>keyboard_arrow_down</mat-icon>
      </div>
    </button>
  </div>

  <mat-menu #companyMenu="matMenu" class="company-menu">
    <div
      class="contract"
      fxLayout="row"
      fxLayoutAlign="left center"
      *ngIf="contract$ | async; let contract; else: noContract"
    >
      <mat-icon>beenhere</mat-icon>

      <div class="right">
        <div class="name">{{ contract?.plan?.name }}</div>
        <div class="date">
          <ng-container *ngIf="contract.end; else noEnd">
            {{ contract.start }} ~ {{ contract.end }}
          </ng-container>
          <ng-template #noEnd i18n> {{ contract.start }} 起 </ng-template>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>

    <ng-template #noContract>
      <div class="contract empty" fxLayout="row" fxLayoutAlign="left center">
        <mat-icon>beenhere</mat-icon>
        <div class="right">
          <div class="name" i18n>沒有任何方案</div>
        </div>
      </div>
    </ng-template>

    <button
      mat-menu-item
      routerLink="/company/settings"
      *ngIf="environment.features.companyInfo"
      i18n
    >
      <mat-icon>settings</mat-icon> 組織設定
    </button>

    <button
      mat-menu-item
      routerLink="/market-place"
      *ngIf="environment.features.payment"
      i18n
    >
      <mat-icon>money</mat-icon> 帳務管理
    </button>
    <button
      mat-menu-item
      element-ref
      #elementRef="elementRef"
      (click)="selectCompany(elementRef, $event)"
      *ngIf="environment.features.changeCompany"
      i18n
    >
      <mat-icon>compare_arrows</mat-icon> 切換組織
    </button>
  </mat-menu>
</ng-container>

<div class="account-button-wrap">
  <button
    class="account-button"
    *ngIf="tokenService.rxAccount$ | async; let account"
    mat-button
    [matMenuTriggerFor]="accountMenu"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    <div class="name" fxFlex="1 0 0">{{ account.accountName }}</div>
    <div class="icon-wrap">
      <mat-icon>keyboard_arrow_down</mat-icon>
    </div>
  </button>
</div>

<mat-menu #accountMenu="matMenu" class="account-menu">
  <button
    mat-menu-item
    routerLink="/account/personal"
    *ngIf="environment.features.changePassword"
    i18n
  >
    <mat-icon>settings</mat-icon> 帳號設定
  </button>

  <button mat-menu-item (click)="logout()" i18n>
    <mat-icon>exit_to_app</mat-icon> 登出
  </button>
</mat-menu>

<ng-template #inner let-announcement>
  <mat-icon [style.color]="announcement!.font_color" *ngIf="announcement!.icon">
    {{ announcement!.icon }}
  </mat-icon>
  {{ announcement!.title }}
</ng-template>

<ng-template #noContractDialog>
  <div
    class="no-contract-dialog"
    *asyncJob="tokenService.company$; let company"
  >
    <gosu-logo [size]="120" theme="dark"></gosu-logo>
    <h3 i18n>方案已到期</h3>
    <div class="flex">
      <div class="left">
        <p i18n>親愛的用戶 您好：</p>
        <p i18n>
          您的組織「{{ company.name }}」使用的方案已結束，若您希望繼續使用 GOSU
          BAR 的服務，請您前往購買，完成購買後即可重新使用。
        </p>

        <p i18n>
          提醒您，我們提供了30天的帳號保留期，如您未於此期間內
          <span class="red">
            {{ keep.start | moment: 'YYYY/MM/DD' }}
            ～
            {{ keep.end | moment: 'YYYY/MM/DD' }}
          </span>
          進行購買，組織「
          {{ company.name }}
          」將會被刪除，您所建立的流程、圖像、標籤、屬性等內容也會一併被清空。
        </p>

        <p i18n>以上，感謝您對 GOSU BAR 的支持。</p>
        <p>
          <button
            i18n
            mat-flat-button
            color="primary"
            routerLink="/payment"
            (click)="matConnectedDialog.closeAll()"
          >
            前往購買
          </button>
        </p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #expiredDialog>
  <div
    class="no-contract-dialog"
    *asyncJob="tokenService.company$; let company"
  >
    <gosu-logo [size]="120" theme="dark"></gosu-logo>
    <ng-container *asyncJob="lastContract$; let contract">
      <h3 i18n>方案已到期</h3>
      <div class="flex">
        <div class="left">
          <p i18n>親愛的用戶 您好：</p>
          <p i18n>
            您的組織「{{ company.name }}」使用的方案， 已於{{
              contract.end || today | moment: 'YYYY/MM/DD'
            }}
            結束， 若您希望繼續使用 GOSU BAR 的服務， 請您前往購買，
            完成購買後即可重新使用。
          </p>

          <p i18n>
            提醒您，我們提供了30天的帳號保留期，如您未於此期間內
            <span class="red">
              {{ keep.start | moment: 'YYYY/MM/DD' }}
              ～
              {{ keep.end | moment: 'YYYY/MM/DD' }}
            </span>
            進行購買，組織「 {{ company.name }} 」將會被刪除，
            您所建立的流程、圖像、標籤、屬性等內容也會一併被清空。
          </p>

          <p i18n>以上，感謝您對 GOSU BAR 的支持。</p>
          <p i18n>
            <button
              mat-flat-button
              color="primary"
              routerLink="/payment"
              (click)="matConnectedDialog.closeAll()"
            >
              前往購買
            </button>
          </p>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #overLimitDialog>
  <div class="over-limit-dialog" *asyncJob="tokenService.company$; let company">
    <img src="./assets/party-popper.png" alt="" />
    <p class="title" i18n>好友人數突破祝賀通知</p>
    <div class="content">
      <p i18n>親愛的用戶 您好：</p>
      <p i18n>
        恭喜您！您用心經營的官方帳號
        <span class="red">
          {{ (company$ | async).name }}
        </span>
        好友人數已經突破
        <span class="red">
          {{ (company$ | async).friends }}
        </span>
        人囉！為了用更穩定的品質服務更龐大的好友數量，請儘速升級您的使用方案，以便繼續使用
        GOSU BAR 的服務喔！
      </p>
      <p i18n>感謝您對 GOSU BAR 的支持！</p>
    </div>

    <div class="stop-notify">
      <mat-checkbox (change)="stopNotify($event)" i18n
        >停止顯示這個通知</mat-checkbox
      >
    </div>
    <button
      class="buy"
      mat-flat-button
      color="primary"
      i18n
      routerLink="/payment"
      (click)="matConnectedDialog.closeAll()"
    >
      前往升級
    </button>
    <button mat-flat-button i18n (click)="matConnectedDialog.closeAll()">
      關閉
    </button>
  </div>
</ng-template>
