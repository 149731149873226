// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { InteractiveDto } from './interactive.dto';
import { SlotsPrizeDto } from './slots-prize.dto';

export class SlotsDto extends InteractiveDto {
  /** 活動類型 */
  public type = 'SLOTS' as const;

  // 活動獎品包
  public ticketEventId?: number;

  // 背景圖
  public background?: string;

  // 是否使用預設 背景圖是否預設
  public isDefaultBackground?: boolean;

  // 拉霸底圖
  public machineImage?: string;

  // 是否使用預設 拉霸底圖
  public isDefaultMachineImage?: boolean;

  // 獎品
  public prizes?: SlotsPrizeDto[];

  // 沒中獎彈窗標題
  public loseTitle?: string;

  // 沒中獎彈窗敘述
  public loseDescription?: string;

  // 沒中獎彈窗圖
  public loseImage?: string;

  // 是否使用預設 沒中獎彈窗圖
  public isDefaultLoseImage?: boolean;
}
// 3148e7a53639b9d82a360bac821b0bc66fdc3ec38162b175d7aa8735db8d55b6
