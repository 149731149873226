import { Injectable, WritableSignal, signal } from '@angular/core';
import {
  MatConnectedDialog,
  MatConnectedDialogConfig,
  MatConnectedDialogRef,
} from '@ay-gosu/ui/common/connected-dialog';
import { DownloadResponse } from '@ay-nestjs/share-client';
import { ExposedPromise } from '@ay/util';
import { Observable, firstValueFrom } from 'rxjs';
import { Button } from './buttons/button.type';
import { ConfirmDialog } from './confirm/confirm.dialog';
import { DeleteDialog } from './delete/delete.dialog';
import { FailureTableComponent } from './failure-table/failure-table.component';
import { FailureDialog } from './failure/failure.component';

import { LoadingComponent } from './loading/loading.component';
import { PromptDialog } from './prompt/prompt.dialog';
import { RenameDialog } from './rename/rename.dialog';
import { SelectDialog } from './select/select.component';
import { SuccessDialog } from './success/success.component';

@Injectable({ providedIn: 'root' })
export class DialogService {
  public constructor(protected readonly _matDialog: MatConnectedDialog) {}

  public async rename(
    name: string,
    config: MatConnectedDialogConfig & { maxLength?: number } = {},
  ): Promise<string | undefined> {
    const dialogRef = this._matDialog.open(RenameDialog, {
      autoFocus: false,
      ...config,
      data: { name, maxLength: config.maxLength },
    });

    name = await firstValueFrom(dialogRef.afterClosed());

    if (!name) {
      return undefined;
    }

    return name;
  }

  /**
   * 由於有很多操作正常情況下反應速度非常快，會在幾毫秒之內完成，如果直接顯示 loading 會造成閃爍
   * 所以這個 loading 僅會在 300ms 之內還沒完成的情況下才顯示載入中的視窗
   * 同樣的如果任務在 300ms ~ 600ms 之間完成，畫面也會有閃爍的問題，所以如果 close 時視窗才剛開啟不到 300ms，會等到開啟後 300ms 才關閉
   * 如果要拿到 loading 的 dialogRef，可以使用 await response.promise;
   */
  public loading(
    text: string = '',
    config: MatConnectedDialogConfig & {
      mode?: 'indeterminate' | 'determinate';
      downloading?: Observable<DownloadResponse>;
    } = {},
  ): {
    dialogRef$: Promise<MatConnectedDialogRef<LoadingComponent, void>>;
    value: WritableSignal<number>;
    close: () => void;
  } {
    const delayOpenMs = 300;
    const delayCloseMs = 300;
    let dialogRef: MatConnectedDialogRef<LoadingComponent, void>;

    const exposedPromise = new ExposedPromise<
      MatConnectedDialogRef<LoadingComponent, void>
    >();

    let openAt: number;

    const value = signal(0);
    const timer = setTimeout(() => {
      openAt = Date.now();
      dialogRef = this._matDialog.open(LoadingComponent, {
        disableClose: true,
        panelClass: text === '' ? 'transparent-dialog-panel' : undefined,
        ...config,
        data: { text, mode: config.mode, value },
      });
      exposedPromise.resolve(dialogRef);
    }, delayOpenMs);

    const close = () => {
      // 視窗還沒開啟，關閉開啟的計時器
      if (!dialogRef) {
        clearTimeout(timer);
        return;
      }

      // 視窗已經開啟，但是還沒到 500ms，等到 500ms 關閉
      const diff = delayCloseMs - (Date.now() - openAt);
      if (diff < 0) {
        setTimeout(() => dialogRef.close(), diff);
      } else {
        // 視窗已經開啟，且已經超過 500ms，直接關閉
        dialogRef.close();
      }
    };

    if (config.downloading) {
      config.downloading.subscribe((event) => {
        if (event.status === 'downloading') {
          value.set(event.percentage!);
        }

        if (event.status === 'done') {
          close();
        }
      });
    }

    return {
      close,
      value,
      dialogRef$: exposedPromise.promise,
    };
  }

  public failure(
    title: string,
    config: MatConnectedDialogConfig & {
      /* 顯示的 ICON, Material Icon 的名稱 */
      icon?: string;
      /* 是否為外框線的圖示 */
      iconOutlined?: boolean;
      /* 說明的文字，透過陣列分隔 */
      messages?: string | string[];
      /* 底部的按鈕，預設為 [確定] 按鈕，詳細設定請看 Button 的 Storybook */
      buttons?: Button[];
    } = {},
  ): Promise<void> {
    const dialogRef = this._matDialog.open(FailureDialog, {
      ...config,
      data: {
        title,
        icon: config.icon,
        iconOutlined: config.iconOutlined,
        messages: config.messages,
        buttons: config.buttons,
      },
    });

    return firstValueFrom(dialogRef.afterClosed());
  }

  public failureTable<T>(
    title: string,
    columns: ({ label: string; field: keyof T } | keyof T)[],
    list: T[],
    config: MatConnectedDialogConfig = {},
  ) {
    const dialogRef = this._matDialog.open(FailureTableComponent, {
      autoFocus: false,
      ...config,
      data: {
        title,
        columns,
        list,
      },
    });

    return firstValueFrom(dialogRef.afterClosed());
  }

  public success(
    /** 標題 */
    title: string,
    config: MatConnectedDialogConfig & {
      /* 顯示的 ICON, Material Icon 的名稱 */
      icon?: string;
      /* 是否為外框線的圖示 */
      iconOutlined?: boolean;
      /* 說明的文字，透過陣列分隔 */
      messages?: string | string[];
      /* 底部的按鈕，預設為 [確定] 按鈕，詳細設定請看 Button 的 Storybook */
      buttons?: Button[];
    } = {},
  ): Promise<void> {
    const dialogRef = this._matDialog.open(SuccessDialog, {
      autoFocus: false,
      ...config,
      data: {
        title,
        icon: config.icon,
        iconOutlined: config.iconOutlined,
        messages: config.messages,
        buttons: config.buttons,
      },
    });

    return firstValueFrom(dialogRef.afterClosed());
  }

  public async select<T = string>(
    title: string,
    current: T,
    options: { label: string; value: T; disabled: boolean }[],
    config: MatConnectedDialogConfig = {},
  ): Promise<T | undefined> {
    const dialogRef = this._matDialog.open(SelectDialog, {
      autoFocus: false,
      ...config,
      data: {
        title,
        current,
        options,
      },
    });

    const value = await firstValueFrom(dialogRef.afterClosed());
    return value;
  }

  public async confirm<T = boolean>(
    title: string,
    description: string = '',
    config: MatConnectedDialogConfig & {
      buttons?: Button<T>[];
    } = {},
  ): Promise<T | undefined> {
    const dialogRef = this._matDialog.open(ConfirmDialog, {
      autoFocus: false,
      ...config,
      data: {
        title,
        description,
        buttons: config.buttons,
      },
    });

    const value = await firstValueFrom(dialogRef.afterClosed());
    return value;
  }

  public async delete(
    name: string,
    config?: MatConnectedDialogConfig & {
      confirmCode?: boolean;
      messages?: string[];
    },
  ): Promise<boolean> {
    const dialogRef = this._matDialog.open(DeleteDialog, {
      ...config,
      data: {
        name: '刪除 ' + name,
        confirmCode: config?.confirmCode ?? true,
        messages: config?.messages || [],
        confirmMessage: '確認碼：',
        confirmButtonText: '確定',
        cancelButtonText: '取消',
      },
    });

    const value = await firstValueFrom(dialogRef.afterClosed());
    return value === true;
  }

  public async confirmCode(
    name: string,
    config?: MatConnectedDialogConfig & {
      confirmCode?: boolean;
      messages?: string[];
      confirmMessage?: string;
      confirmButtonText?: string;
      cancelButtonText?: string;
    },
  ): Promise<boolean> {
    const dialogRef = this._matDialog.open(DeleteDialog, {
      ...config,
      data: {
        name,
        confirmCode: config?.confirmCode ?? true,
        messages: config?.messages || [],
        confirmMessage: config?.confirmMessage ?? '請輸入確認碼：',
        confirmButtonText: config?.confirmButtonText ?? '確定',
        cancelButtonText: config?.cancelButtonText ?? '取消',
      },
      width: '415px',
    });

    const value = await firstValueFrom(dialogRef.afterClosed());
    return value === true;
  }

  public async prompt(
    title: string,
    config: MatConnectedDialogConfig & { text?: string } = {},
  ): Promise<string> {
    const dialogRef = this._matDialog.open(PromptDialog, {
      ...config,
      data: { title, text: config.text },
    });

    const value = await firstValueFrom(dialogRef.afterClosed());
    return value;
  }
}
