import { Component, HostBinding, Input } from '@angular/core';
import { Flex } from '@ay/bot';

@Component({
    selector: 'flex-span',
    templateUrl: './span.component.html',
    styleUrls: ['./span.component.scss'],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        '[style.color]': 'data.color',
        '[innerHTML]': 'dataText',
    },
    standalone: true,
})
export class FlexSpanComponent {
  @Input()
  public data: Flex.Block.Span;

  @Input()
  public bubble: Flex.Content.Bubble;

  @Input()
  public flex: Flex.Message;

  @Input()
  public level: number;

  public sizeClass = {
    xxs: 'ExXXs',
    xs: 'ExXs',
    sm: 'ExSm',
    md: 'ExMd',
    lg: 'ExLg',
    xl: 'ExXl',
    xxl: 'ExXXl',
    '3xl': 'Ex3Xl',
    '4xl': 'Ex4Xl',
    '5xl': 'Ex5Xl',
  };

  public weightClass = {
    regular: 'ExWR',
    bold: 'ExWB',
  };

  public styleClass = {
    normal: 'ExFntStyNml',
    italic: 'ExFntStyIt',
  };

  protected decorationClass = {
    none: 'ExTxtDecNone',
    underline: 'ExTxtDecUl',
    'line-through': 'ExTxtDecLt',
  };

  public get dataText() {
    if (!this.data.text) return;
    // https://stackoverflow.com/a/71619350/4861109 表情符號切割
    let texts = [];
    if (Intl && Intl['Segmenter']) {
      texts = [...new Intl['Segmenter']().segment(this.data.text)].map(
        (item) => item.segment,
      );
    } else {
      texts = this.data.text.split('');
    }
    return texts
      .map(
        (d) =>
          `<font class="${this.weightClass[this.data.weight] || ''} ${
            this.styleClass[this.data.style] || ''
          }">${d}</font>`,
      )
      .join('');
  }

  @HostBinding('class')
  public get hostClass(): string {
    const list = ['MdSpn', 'fxC' + this.level];
    list.push(this.sizeClass[this.data.size]);
    list.push(this.weightClass[this.data.weight]);
    list.push(this.styleClass[this.data.style]);
    list.push(this.decorationClass[this.data.decoration]);
    return list.filter(Boolean).join(' ');
  }
}
