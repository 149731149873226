import { firstValueFrom } from 'rxjs';
import { PageService } from '../../service/page.service';
import { registerNode } from './node';
import {
  AwaitFormComponent,
  AwaitNode,
  AwaitNodeComponent,
} from './node/await';
import {
  CustomerServiceFormComponent,
  CustomerServiceNode,
  CustomerServiceNodeComponent,
} from './node/customer-service';
import {
  DirectusFormComponent,
  DirectusNode,
  DirectusNodeComponent,
} from './node/directus';
import {
  AccountLinkEventNode,
  AddCommentNode,
  AddIGCommentNode,
  AddLikePageNode,
  AddPostCustomerNode,
  AddPostEditorsNode,
  AddRatingsNode,
  AddReactionNode,
  AddThroughPromotionEventNode,
  AudioMessageEventNode,
  ClickBeaconBannerEventNode,
  EditedPostCustomerNode,
  EditedPostEditorsNode,
  EditReactionNode,
  EnterBeaconEventNode,
  EventFormComponent,
  EventNodeComponent,
  ExchangeEventNode,
  FollowEventNode,
  IGMEEventNode,
  ImageMessageEventNode,
  JoinEventNode,
  LeaveBeaconEventNode,
  LeaveEventNode,
  LocationMessageEventNode,
  MemberJoinedEventNode,
  MemberLeftEventNode,
  MessageEventNode,
  MMEEventNode,
  PostbackEventNode,
  ReceiveCouponEventNode,
  RemoveCommentNode,
  RemoveLikePageNode,
  RemovePostCustomerNode,
  RemovePostEditorsNode,
  RemoveRatingsNode,
  RemoveReactionNode,
  StayBeaconEventNode,
  SuccessInviteFriendThroughPromotionEventNode,
  SurveyCakeEventNode,
  TextMessageEventNode,
  UnfollowEventNode,
  UntreatedEventNode,
  UseCouponEventNode,
  VideoMessageEventNode,
  WebhookEventNode,
} from './node/event';
import {
  ExchangeFormComponent,
  ExchangeNode,
  ExchangeNodeComponent,
} from './node/exchange';
import {
  ExecuteAPIFormComponent,
  ExecuteAPINode,
  ExecuteAPINodeComponent,
} from './node/execute-api';
import {
  ExecuteFTPFormComponent,
  ExecuteFTPNode,
  ExecuteFTPNodeComponent,
} from './node/execute-ftp';
import {
  FindPackageFormComponent,
  FindPackageNodeComponent,
  FindRecordNode,
} from './node/find-package';
import {
  FindProfileFormComponent,
  FindProfileNode,
  FindProfileNodeComponent,
} from './node/findProfile';
import { FuncFormComponent, FuncNode, FuncNodeComponent } from './node/func';
import {
  GetAccountLinkUrlFormComponent,
  GetAccountLinkUrlNode,
  GetAccountLinkUrlNodeComponent,
} from './node/get-account-link-url';
import {
  GetStatisticFormComponent,
  GetStatisticNode,
  GetStatisticNodeComponent,
} from './node/get-statistic';
import {
  GetterFormComponent,
  GetterNode,
  GetterNodeComponent,
} from './node/getter';
import {
  LeaveFormComponent,
  LeaveNode,
  LeaveNodeComponent,
} from './node/leave';
import {
  MemberFormComponent,
  MemberNode,
  MemberNodeComponent,
} from './node/member';
import {
  RandomFormComponent,
  RandomNode,
  RandomNodeComponent,
} from './node/random';
import {
  ReplyFormComponent,
  ReplyNode,
  ReplyNodeComponent,
} from './node/reply';
import {
  ResponseFormComponent,
  ResponseNode,
  ResponseNodeComponent,
} from './node/response';
import {
  RichMenuFormComponent,
  RichMenuNode,
  RichMenuNodeComponent,
} from './node/richMenu';
import { SendFormComponent, SendNode, SendNodeComponent } from './node/send';
import {
  SendStackerFormComponent,
  SendStackerNode,
  SendStackerNodeComponent,
} from './node/send-stacker';
import {
  SetterFormComponent,
  SetterNode,
  SetterNodeComponent,
} from './node/setter';
import {
  SwitchFormComponent,
  SwitchNode,
  SwitchNodeComponent,
} from './node/switch';
import { TextNode } from './node/text/class';
import { TextFormComponent } from './node/text/form.component';
import { TextNodeComponent } from './node/text/node.component';
import {
  TimerFormComponent,
  TimerNode,
  TimerNodeComponent,
} from './node/timer';

export function registeNodes() {
  //#region event
  registerNode(
    {
      name: $localize`自定義事件`,
      type: 'WebhookEventNode',
      node: WebhookEventNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`受邀加入群組時`,
      type: 'JoinEventNode',
      node: JoinEventNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`被踢離群組時`,
      type: 'LeaveEventNode',
      node: LeaveEventNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`被加入好友時`,
      type: 'FollowEventNode',
      node: FollowEventNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`被封鎖時`,
      type: 'UnfollowEventNode',
      node: UnfollowEventNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`收到回呼函數時`,
      type: 'PostbackEventNode',
      node: PostbackEventNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`收到任意訊息時`,
      type: 'MessageEventNode',
      node: MessageEventNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`當訊息未被處理時`,
      type: 'UntreatedEventNode',
      node: UntreatedEventNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`收到文字時`,
      type: 'TextMessageEventNode',
      node: TextMessageEventNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
      toolbar: {
        name: $localize`起始點`,
        icon: 'arrow_forward',
        color: '#18AB8E',
      },
    },
    {
      name: $localize`收到圖片時`,
      type: 'ImageMessageEventNode',
      node: ImageMessageEventNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`收到影片時`,
      type: 'VideoMessageEventNode',
      node: VideoMessageEventNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`收到聲音時`,
      type: 'AudioMessageEventNode',
      node: AudioMessageEventNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`收到地理位置時`,
      type: 'LocationMessageEventNode',
      node: LocationMessageEventNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },

    {
      name: $localize`IG 別人回應文章`,
      type: 'AddIGCommentNode',
      node: AddIGCommentNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`小編在粉絲專頁發文`,
      type: 'AddPostEditorsNode',
      node: AddPostEditorsNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`小編刪除粉絲專頁上的貼文`,
      type: 'RemovePostEditorsNode',
      node: RemovePostEditorsNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`別人在粉絲專頁發文`,
      type: 'AddPostCustomerNode',
      node: AddPostCustomerNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`別人刪除粉絲專頁上的貼文`,
      type: 'RemovePostCustomerNode',
      node: RemovePostCustomerNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`粉絲專頁收到讚`,
      type: 'AddLikePageNode',
      node: AddLikePageNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`粉絲專頁的讚被收回`,
      type: 'RemoveLikePageNode',
      node: RemoveLikePageNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`新增評論`,
      type: 'AddRatingsNode',
      node: AddRatingsNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`刪除評論`,
      type: 'RemoveRatingsNode',
      node: RemoveRatingsNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`別人回應文章`,
      type: 'AddCommentNode',
      node: AddCommentNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`別人刪除回應`,
      type: 'RemoveCommentNode',
      node: RemoveCommentNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`使用者對某則貼文按讚或心情`,
      type: 'AddReactionNode',
      node: AddReactionNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`使用者編輯某則貼文的按讚或心情`,
      type: 'EditReactionNode',
      node: EditReactionNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`使用者收回某則貼文的按讚或心情`,
      type: 'RemoveReactionNode',
      node: RemoveReactionNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`別人編輯粉絲專頁上的貼文`,
      type: 'EditedPostCustomerNode',
      node: EditedPostCustomerNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`小編編輯粉絲專頁的文章`,
      type: 'EditedPostEditorsNode',
      node: EditedPostEditorsNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`群組有新成員`,
      type: 'MemberJoinedEventNode',
      node: MemberJoinedEventNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`群組成員退出`,
      type: 'MemberLeftEventNode',
      node: MemberLeftEventNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`成功鏈結帳號`,
      type: 'AccountLinkEventNode',
      node: AccountLinkEventNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`當使用者使用優惠券`,
      type: 'UseCouponEventNode',
      node: UseCouponEventNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`當使用者收到優惠券`,
      type: 'ReceiveCouponEventNode',
      node: ReceiveCouponEventNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`m.me 連結`,
      type: 'MMEEventNode',
      node: MMEEventNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`ig.me 連結`,
      type: 'IGMEEventNode',
      node: IGMEEventNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`當使用者成功兌換獎品`,
      type: 'ExchangeEventNode',
      node: ExchangeEventNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`當使用者填寫完表單`,
      type: 'SurveyCakeEventNode',
      node: SurveyCakeEventNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`進入 Beacon 範圍`,
      type: 'EnterBeaconEventNode',
      node: EnterBeaconEventNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`點擊 Beacon Banner 時`,
      type: 'ClickBeaconBannerEventNode',
      node: ClickBeaconBannerEventNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`待在 Beacon 範圍內(間隔10秒(最少)觸發一次)`,
      type: 'StayBeaconEventNode',
      node: StayBeaconEventNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`離開 Beacon 範圍（LINE 將於 2020 年底棄用）`,
      type: 'LeaveBeaconEventNode',
      node: LeaveBeaconEventNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`透過推廣通路加入好友`,
      type: 'AddThroughPromotionEventNode',
      node: AddThroughPromotionEventNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
    {
      name: $localize`成功透過推廣通路邀請好友`,
      type: 'SuccessInviteFriendThroughPromotionEventNode',
      node: SuccessInviteFriendThroughPromotionEventNode,
      nodeComponent: EventNodeComponent,
      formComponent: EventFormComponent,
    },
  );
  //#endregion event

  registerNode({
    name: $localize`決策點`,
    type: 'SwitchNode',
    node: SwitchNode,
    nodeComponent: SwitchNodeComponent,
    formComponent: SwitchFormComponent,
    toolbar: {
      icon: 'shuffle',
      color: '#35495D',
    },
  });

  registerNode({
    name: $localize`發送訊息`,
    type: 'SendNode',
    node: SendNode,
    nodeComponent: SendNodeComponent,
    formComponent: SendFormComponent,
    toolbar: {
      icon: 'textsms',
      color: '#308BC8',
    },
  });

  registerNode({
    name: $localize`設定屬性`,
    type: 'SetterNode',
    node: SetterNode,
    nodeComponent: SetterNodeComponent,
    formComponent: SetterFormComponent,
    toolbar: {
      icon: 'label',
      color: '#9B59B6',
    },
  });

  registerNode({
    name: $localize`讀取屬性`,
    type: 'GetterNode',
    node: GetterNode,
    nodeComponent: GetterNodeComponent,
    formComponent: GetterFormComponent,
    toolbar: {
      icon: 'assignment',
      color: '#7E57C2',
    },
  });

  registerNode({
    name: $localize`圖文選單`,
    type: 'RichMenuNode',
    node: RichMenuNode,
    nodeComponent: RichMenuNodeComponent,
    formComponent: RichMenuFormComponent,
    toolbar: {
      icon: 'menu',
      tag: $localize`LINE Messaging API 限定`,
      color: '#333333',
    },
  });

  registerNode({
    name: $localize`隨機`,
    type: 'RandomNode',
    node: RandomNode,
    nodeComponent: RandomNodeComponent,
    formComponent: RandomFormComponent,
    toolbar: {
      icon: 'donut_small',
      color: '#A1887F',
    },
  });

  registerNode({
    name: $localize`回覆貼文留言`,
    type: 'ReplyNode',
    node: ReplyNode,
    nodeComponent: ReplyNodeComponent,
    formComponent: ReplyFormComponent,
    toolbar: {
      icon: 'reply',
      tag: $localize`Facebook , Instagram 限定`,
      color: '#3B8DC4',
    },
  });

  registerNode({
    name: $localize`離開群組`,
    type: 'LeaveNode',
    node: LeaveNode,
    nodeComponent: LeaveNodeComponent,
    formComponent: LeaveFormComponent,
    toolbar: {
      icon: 'directions_run',
      tag: $localize`LINE 限定`,
      color: '#FFB300',
    },
  });
  registerNode({
    name: $localize`執行API`,
    type: 'ExecuteAPINode',
    node: ExecuteAPINode,
    nodeComponent: ExecuteAPINodeComponent,
    formComponent: ExecuteAPIFormComponent,
    toolbar: {
      icon: 'http',
      color: '#BF360C',
      expert: true,
    },
  });

  registerNode({
    name: $localize`透過 FTP 讀取資料`,
    type: 'ExecuteFTPNode',
    node: ExecuteFTPNode,
    nodeComponent: ExecuteFTPNodeComponent,
    formComponent: ExecuteFTPFormComponent,
    toolbar: {
      icon: 'insert_drive_file',
      color: '#D14F2E',
      expert: true,
    },
  });

  registerNode({
    name: $localize`執行程式碼`,
    type: 'FuncNode',
    node: FuncNode,
    nodeComponent: FuncNodeComponent,
    formComponent: FuncFormComponent,
    toolbar: {
      icon: 'code',
      color: '#E74C3C',
      expert: true,
    },
  });

  registerNode({
    name: $localize`回應請求`,
    type: 'ResponseNode',
    node: ResponseNode,
    nodeComponent: ResponseNodeComponent,
    formComponent: ResponseFormComponent,
    toolbar: {
      icon: 'send',
      color: '#27AE60',
      expert: true,
    },
  });

  registerNode({
    name: $localize`等待`,
    type: 'TimerNode',
    node: TimerNode,
    nodeComponent: TimerNodeComponent,
    formComponent: TimerFormComponent,
    toolbar: {
      icon: 'timer',
      color: '#26C6DA',
    },
  });

  registerNode({
    name: $localize`非同步等待器`,
    type: 'AwaitNode',
    node: AwaitNode,
    nodeComponent: AwaitNodeComponent,
    formComponent: AwaitFormComponent,
    toolbar: {
      icon: 'restore',
      color: '#F57C00',
      expert: true,
    },
  });

  registerNode({
    name: $localize`發送疊加器`,
    type: 'SendStackerNode',
    node: SendStackerNode,
    nodeComponent: SendStackerNodeComponent,
    formComponent: SendStackerFormComponent,
    toolbar: {
      icon: 'question_answer',
      color: '#795548',
      expert: true,
    },
  });

  registerNode({
    name: $localize`查詢對象`,
    type: 'FindProfileNode',
    node: FindProfileNode,
    nodeComponent: FindProfileNodeComponent,
    formComponent: FindProfileFormComponent,
    toolbar: {
      icon: 'search',
      color: '#0097A7',
      expert: true,
    },
  });

  registerNode({
    name: $localize`群組成員清單`,
    type: 'MemberNode',
    node: MemberNode,
    nodeComponent: MemberNodeComponent,
    formComponent: MemberFormComponent,
    toolbar: {
      icon: 'people',
      tag: $localize`LINE 限定`,
      color: '#ad1457',
      expert: true,
    },
  });

  registerNode({
    name: $localize`讀取發送紀錄`,
    type: 'FindRecordNode',
    node: FindRecordNode,
    nodeComponent: FindPackageNodeComponent,
    formComponent: FindPackageFormComponent,
    toolbar: {
      icon: 'list',
      color: '#2c3e50',
      expert: true,
      hidden: true,
    },
  });

  registerNode({
    name: $localize`讀取報表資料`,
    type: 'GetStatisticNode',
    node: GetStatisticNode,
    nodeComponent: GetStatisticNodeComponent,
    formComponent: GetStatisticFormComponent,
    toolbar: {
      icon: 'insert_chart',
      color: '#2c3e50',
      expert: true,
      hidden: true,
    },
  });

  registerNode({
    name: $localize`讀取帳戶鏈結網址`,
    type: 'GetAccountLinkUrlNode',
    node: GetAccountLinkUrlNode,
    nodeComponent: GetAccountLinkUrlNodeComponent,
    formComponent: GetAccountLinkUrlFormComponent,
    toolbar: {
      icon: 'insert_link',
      color: '#4abf72',
      tag: $localize`LINE 限定`,
      expert: true,
    },
  });

  registerNode({
    name: $localize`說明文字`,
    type: 'TextNode',
    node: TextNode,
    nodeComponent: TextNodeComponent,
    formComponent: TextFormComponent,
    toolbar: {
      icon: 'font_download',
      color: '#000000',
      expert: false,
      hidden: false,
    },
  });

  registerNode({
    name: $localize`兌換`,
    type: 'ExchangeNode',
    node: ExchangeNode,
    nodeComponent: ExchangeNodeComponent,
    formComponent: ExchangeFormComponent,
    toolbar: {
      icon: 'fa-exchange-alt',
      color: '#ffc109',
      expert: true,
      hiddenFn: async (pageService: PageService) => {
        const pages = await firstValueFrom(pageService.list$);
        const exist = pages.find((page) => page.path === '/exchange');
        return exist === undefined;
      },
    },
  });

  registerNode({
    name: $localize`內容管理(Directus)`,
    type: 'DirectusNode',
    node: DirectusNode,
    nodeComponent: DirectusNodeComponent,
    formComponent: DirectusFormComponent,
    toolbar: {
      icon: '/assets/directus.svg',
      color: '#2196f3',
      expert: true,
      hiddenFn: async (pageService: PageService) => {
        const pages = await firstValueFrom(pageService.list$);
        const exist = pages.find((page) => page.path === '/directus');
        return exist === undefined;
      },
    },
  });

  registerNode({
    name: '客服模式',
    type: 'CustomerServiceNode',
    node: CustomerServiceNode,
    nodeComponent: CustomerServiceNodeComponent,
    formComponent: CustomerServiceFormComponent,
    toolbar: {
      icon: 'support_agent',
      color: '#0A234E',
      expert: true,
      hiddenFn: async (pageService: PageService) => {
        const pages = await firstValueFrom(pageService.list$).then((pages) =>
          pages.map((page) => [page, ...page.children]).flat(),
        );

        const exist = pages.find((page) =>
          page?.path?.startsWith('/customer-service'),
        );
        return exist === undefined;
      },
    },
  });
}
