// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { Content } from '@ay/bot';
import { TemplateDto } from './dto/template.dto';
import { TemplateType } from './dto/type.type';

export class TemplateModel {
  static list(): Promise<TemplateDto[]> {
    return wsc.execute('/ws/template/list') as any;
  }

  static create(
    name: string,
    type: TemplateType,
    width: number,
    height: number,
    layers: { name: string; data: string }[],
    properties: { key: string; type: string; default: string; name: string }[],
    resources: { id: number; key: string; properties: string }[],
    data: string,
  ): Promise<TemplateDto> {
    return wsc.execute(
      '/ws/template/create',
      name,
      type,
      width,
      height,
      layers,
      properties,
      resources,
      data,
    ) as any;
  }

  static update(
    id: number,
    layers: { name: string; data: string }[],
    properties: { key: string; type: string; default: string; name: string }[],
    resources: { id: number; key: string; properties: string }[],
    data: string,
  ): Promise<boolean> {
    return wsc.execute(
      '/ws/template/update',
      id,
      layers,
      properties,
      resources,
      data,
    ) as any;
  }

  static rename(id: number, name: string): Promise<boolean> {
    return wsc.execute('/ws/template/rename', id, name) as any;
  }

  static resize(id: number, width: number, height: number): Promise<boolean> {
    return wsc.execute('/ws/template/resize', id, width, height) as any;
  }

  static get(id: number): Promise<TemplateDto> {
    return wsc.execute('/ws/template/get', id) as any;
  }

  static remove(id: number): Promise<boolean> {
    return wsc.execute('/ws/template/remove', id) as any;
  }

  static exec(
    id: number,
    properties: any = {},
    force: any = false,
    templateSnapshotId: number | null = null,
  ): Promise<Content.Base> {
    return wsc.execute(
      '/ws/template/exec',
      id,
      properties,
      force,
      templateSnapshotId,
    ) as any;
  }

  static getMultiple(ids: number[]): Promise<TemplateDto[]> {
    return wsc.execute('/ws/template/getMultiple', ids) as any;
  }
}
// 5cbd0aea4c5e1cb547b074efaaa1c9ff49cf7567c6262c2aa12f1b039bec8884
