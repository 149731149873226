import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { EntriesPipe } from '@ay-gosu/ui/common/entries';

@Component({
  selector: 'gosu-form-error',
  standalone: true,
  imports: [CommonModule, EntriesPipe, MatIconModule],
  template: `
    @for (
      entry of control().errors | entries;
      track entry.key;
      let last = $last
    ) {
      @switch (entry.key) {
        @case ('required') {
          <span i18n>必填項目</span>
        }

        @case ('email') {
          <span i18n>電子郵件格式錯誤</span>
        }

        @case ('minlength') {
          <span i18n>最少{{ entry.value.requiredLength }}個字</span>
        }

        @case ('maxlength') {
          <span i18n>最多{{ entry.value.requiredLength }}個字</span>
        }

        @case ('confirm') {
          <span i18n>兩次輸入的{{ entry.value.label }}不一致</span>
        }

        @case ('wrongAccountOrPassword') {
          <span i18n>帳號或密碼錯誤</span>
        }

        @case ('punishTime') {
          <span i18n>
            帳號或密碼錯誤次數過多，請
            {{ entry.value }}
            分鐘後再試
          </span>
        }

        @case ('accountExist') {
          <span i18n>帳號已存在</span>
        }

        @case ('invalidTime') {
          <span i18n>錯誤的時間格式</span>
        }

        @case ('partyLineLoginWrongChannelIdOrSecret') {
          <span i18n>
            <mat-icon class="-mt-[2px]">error</mat-icon>
            LINE LOGIN 設定不完全，請<a
              href="https://u.gosu.bar/party-line-login-wrong-channel-id-or-secret"
              target="_new"
              class="underline"
            >
              點此進行設定
            </a>
          </span>
        }

        @case ('partyLineLoginWrongCallbackUrl') {
          <span i18n>
            <mat-icon class="-mt-[2px]">error</mat-icon>
            LINE LOGIN 設定不完全，請<a
              href="https://u.gosu.bar/party-line-login-wrong-callback-url"
              target="_new"
              class="underline"
            >
              點此進行設定
            </a>
          </span>
        }

        @case ('min') {
          <span i18n>最小值為{{ entry.value.min }}</span>
        }

        @case ('max') {
          <span i18n>最大值為{{ entry.value.max }}</span>
        }

        @case ('upperSnackCase') {
          <span i18n>大寫英文、底線、數字、大寫英文開頭</span>
        }
      }

      @if (!last) {
        <span>、</span>
      }
    }
  `,
  host: {
    class: 'text-xs',
  },
})
export class FormErrorComponent {
  public control = input.required<FormControl>();
}
