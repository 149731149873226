import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translate',
  pure: true,
  standalone: true,
})
export class TranslatePipe implements PipeTransform {
  public translateMap = {
    群發訊息: $localize`群發訊息`,
    文章管理: $localize`文章管理`,
    好友清單: $localize`好友清單`,
    '1 : 1 私訊': $localize`1 : 1 私訊`,
    圖文選單: $localize`圖文選單`,
    流程編輯器: $localize`流程編輯器`,
    圖像編輯器: $localize`圖像編輯器`,
    設定: $localize`設定`,
    帳號管理: $localize`帳號管理`,
    機器人管理: $localize`機器人管理`,
    標籤管理: $localize`標籤管理`,
    屬性管理: $localize`屬性管理`,
    資源管理: $localize`資源管理`,
    關鍵字管理: $localize`關鍵字管理`,
    問題回報: $localize`問題回報`,
    變更密碼: $localize`變更密碼`,
    統計報表: $localize`統計報表`,
    篩選: $localize`篩選`,
    優惠券: $localize`優惠券`,
    兌換活動: $localize`兌換活動`,
    獎品管理: $localize`獎品管理`,
    '內容管理 (Directus)': $localize`內容管理 (Directus)`,
    應用範本: $localize`應用範本`,
    應用市集: $localize`應用市集`,
    真人客服: $localize`真人客服`,
    服務記錄: $localize`服務記錄`,
    客服設定: $localize`客服設定`,
    休息日設定: $localize`休息日設定`,
    罐頭訊息設定: $localize`罐頭訊息設定`,
    歸檔類別設定: $localize`歸檔類別設定`,
    客服操作相關設定: $localize`客服操作相關設定`,
    權限設定: $localize`權限設定`,
    留言板: $localize`留言板`,
    報表系統: $localize`報表系統`,
    身份權限設定: $localize`身份權限設定`,
    內容管理: $localize`內容管理`,
    線上支援: $localize`線上支援`,
    使用者設定: $localize`使用者設定`,
    任務抽獎遊戲: $localize`任務抽獎遊戲`,
    訊息來源設定: $localize`訊息來源設定`,
    接單中心: $localize`接單中心`,
  };

  public transform(input: string): string {
    return this.translateMap[input] ?? input;
  }
}
