import { PropertyConfigDto, RuleObject, TagDto } from '@ay-gosu/server-shared';
import { RuleOp, RuleType } from '@ay-gosu/types';
import isEqual from 'lodash/isEqual';
import { BotService } from '../../../service/bot.service';
import { FilterService } from '../../../service/filter.service';
import { ProfileService } from '../../../service/profile.service';
import { PropertyConfigService } from '../../../service/property-config.service';
import { TagService } from '../../../service/tag.service';
import { CanCreateShortCode } from '../shortcode';
import { RuleComponent } from './rule.component';

export type FromJsonOption = {
  tagService: TagService;
  profileService: ProfileService;
  propertyConfigService: PropertyConfigService;
  botService: BotService;
  filterService: FilterService;
};

export abstract class Rule<
  VALUE_TYPE = string | number | Date | (string | number | Date)[],
> implements CanCreateShortCode
{
  public static schema: string = '';
  public class: 'RULE' | 'GROUP' = 'RULE';
  public id: number = -1;
  public component: RuleComponent;
  public type: RuleType;
  public tag: TagDto;
  public property: PropertyConfigDto;
  public error: any;
  public value: VALUE_TYPE;

  public value2: number | Date;
  public op: RuleOp;

  public constructor(type: RuleType) {
    this.type = type;
  }

  public propertyRuleToJSON(json: any) {
    json.property = this.property.key;
    if (this.op == 'BETWEEN') {
      json.value = this.value;
      json.value2 = this.value2;
    } else {
      json.value = this.value;
    }
  }

  public abstract afterTypeChanged(isUserEvent?: boolean);

  public isEqual(rule: Rule) {
    if (rule.class !== 'RULE') return false;
    return isEqual(this.toRuleObject(), rule.toRuleObject());
  }

  public similar(keyword: string): boolean {
    throw new Error('Method not implemented.');
  }

  public async fromJSON(json: any, option: FromJsonOption): Promise<void> {
    this.op = json.op;
    this.value = json.value;
    if (json.id) {
      this.id = json.id;
    }
  }

  public toRuleObject(json: any = {}): RuleObject {
    json.class = this.class;
    json.type = this.type;
    json.op = this.op;
    if (this.id) {
      json.id = this.id;
    }
    return json;
  }

  public abstract toString();

  public abstract toShortCode();

  public static async fromShortCode(
    code: string,
    option: FromJsonOption,
  ): Promise<Rule> {
    throw new Error(`method not implemented`);
  }

  public abstract checkError();
}
