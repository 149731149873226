<div
  class="quick-replies"
  cdkDropList
  cdkDropListOrientation="horizontal"
  (cdkDropListDropped)="dropped($event)"
  *ngIf="package"
>
  <div
    class="quick-reply"
    *ngFor="let quickReply of package.quickReplies"
    matRipple
    cdkDrag
    (click)="edit(quickReply)"
    (mousedown)="selected = quickReply"
    [matContextMenu]="contextMenu"
    #quickReplies
    i18n
    [class.error]="getError(quickReply)"
  >
    <img *ngIf="quickReply.icon" class="icon" [src]="quickReply.icon" />
    {{ quickReply.label || '按鈕名稱未設定' }}
  </div>
  <div
    class="quick-reply"
    matRipple
    (click)="add()"
    *ngIf="mode === 'EDIT'"
    i18n
  >
    <mat-icon class="mat-18">add</mat-icon>新增快速回應
  </div>
</div>

<mat-menu #contextMenu="matMenu" class="tiny-menu">
  <button mat-menu-item (click)="edit(selected)" i18n>
    <mat-icon>edit</mat-icon> 編輯
  </button>
  <button mat-menu-item (click)="delete(selected)" i18n>
    <mat-icon>delete</mat-icon> 刪除
  </button>
  <button
    mat-menu-item
    (click)="leftSwap(selected)"
    [disabled]="isFirstQuickReply"
  >
    <mat-icon>arrow_back</mat-icon> 左移
  </button>
  <button
    mat-menu-item
    (click)="rightSwap(selected)"
    [disabled]="isLastQuickReply"
    i18n
  >
    <mat-icon>arrow_forward</mat-icon> 右移
  </button>
</mat-menu>
