import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { RegularizedFlex } from '../../regularized-flex.class';
import { MatButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatCard, MatCardImage, MatCardContent } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { NgFor, AsyncPipe } from '@angular/common';

export type Element = {
  title: string;
  imgUrl: string;
  data: any;
};
@Component({
  selector: 'app-component-dialog',
  templateUrl: './component-dialog.component.html',
  styleUrls: ['./component-dialog.component.scss'],
  standalone: true,
  imports: [
    NgFor,
    MatCard,
    MatCardImage,
    MatCardContent,
    MatDialogModule,
    MatDivider,
    MatButton,
    AsyncPipe,
  ],
})
export class FlexComponentDialogComponent {
  public selected: RegularizedFlex;

  public elements$: Observable<Element[]> = environment.directus.url
    ? this._httpClient
        .get(
          `${environment.directus.url}/${environment.directus.project}/items/flex_elements`,
          { params: { fields: 'title, image.data, data' } },
        )
        .pipe(
          map((res) =>
            res['data'].map(
              (row: {
                title: string;
                data: string;
                image: { data: { full_url: string } };
              }) => {
                const { title, data, image } = row;
                return {
                  title,
                  data: JSON.parse(data),
                  imgUrl: image.data.full_url,
                };
              },
            ),
          ),
        )
    : of([]);

  public constructor(private readonly _httpClient: HttpClient) {}
}
