// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { Observable } from 'rxjs';

export class LogModel {
  static setReadingTime(profileId: number): Promise<boolean> {
    return wsc.execute('/ws/log/setReadingTime', profileId) as any;
  }

  static listenReadingTime(): Observable<{ profileId: number }> {
    return wsc.subscribe('/ws/log/listenReadingTime') as any;
  }

  static fetchLog(params: {
    companyId: number;
    date: string;
    keyword: string;
    page: number;
    pageSize: number;
  }): Promise<{ data: Record<string, any>[]; isEnd: boolean }> {
    return wsc.execute('/ws/log/fetchLog', params) as any;
  }

  static monitor(profileId: number): Observable<any> {
    return wsc.subscribe('/ws/log/monitor', profileId) as any;
  }

  static monitorLogger(companyId: number): Observable<Record<string, any>> {
    return wsc.subscribe('/ws/log/monitorLogger', companyId) as any;
  }

  static keepMonitorLogger(companyId: number): Promise<void> {
    return wsc.execute('/ws/log/keepMonitorLogger', companyId) as any;
  }

  static stopMonitorLogger(companyId: number): Promise<void> {
    return wsc.execute('/ws/log/stopMonitorLogger', companyId) as any;
  }
}
// 2ef5802de7f572524e068086b83d3c6a59dda3e629fd287683b30d58820462d0
