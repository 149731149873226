import { DataSource } from '@angular/cdk/table';
import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { Observable, Subject, takeUntil } from 'rxjs';
import { NodeData } from './node-data';
import { MatTreePickerNodeDef } from './tree-picker-node';
import { MatTreePickerNodeList } from './tree-picker-node-list';
import { NgClass } from '@angular/common';

@Component({
  selector: 'mat-tree-picker',
  template: `
    <ng-template #panel>
      <div class="mat-tree-picker-panel" [ngClass]="_classList" role="listbox">
        <mat-tree-picker-node-list
          #list
          [dataSource]="dataSource"
          [nodeDef]="matTreePickerNode"
          [side]="side"
          [classList]="_classList"
        ></mat-tree-picker-node-list>
      </div>
    </ng-template>
  `,
  styleUrls: ['tree-picker.scss'],
  exportAs: 'matTreePicker',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'mat-tree-picker',
    role: 'tree',
  },
  standalone: true,
  imports: [NgClass, MatTreePickerNodeList],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class MatTreePicker<T extends NodeData = NodeData>
  extends MatMenu
  implements OnInit, OnDestroy
{
  @Input()
  public dataSource: DataSource<T> | Observable<T[]> | T[];

  @ViewChild('panel')
  public panel: TemplateRef<any>;

  @Input()
  public panelWidth: number;

  @ContentChild(MatTreePickerNodeDef, { static: true })
  public matTreePickerNode: MatTreePickerNodeDef<T>;

  @ViewChild(MatTreePickerNodeList, { static: false })
  public list: MatTreePickerNodeList<T>;

  @Input()
  public side: string = 'right';

  @Output()
  public selectNotFoundNode = new EventEmitter<void>();

  private readonly _destroy$ = new Subject<void>();

  public closePanel(parent = false) {
    this.list.closePanel(parent);
  }

  public selectPreviousNode() {
    this.list.selectPreviousNode();
  }

  public selectNextNode() {
    this.list.selectNextNode();
  }

  public selectPreviousLevel() {
    this.list.selectPreviousLevel();
  }

  public selectNextLevel() {
    this.list.selectNextLevel();
  }

  public selectNode() {
    this.list.selectNode();
  }

  public ngOnInit() {
    super.ngOnInit();
    this.closed
      .pipe(takeUntil(this._destroy$))
      .subscribe((event) => this.closePanel(true));
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
    this._destroy$.next();
    this._destroy$.complete();
  }
}
