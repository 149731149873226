import { Inject, Injectable } from '@angular/core';
import {
  PackageFetchForBroadcast,
  PackageFetchForSendNode,
  PackageSaveForBroadcast,
  PackageSaveForPromotionChannel,
  PackageSaveForSendNode,
  PackageStatus,
  TagDto,
} from '@ay-gosu/server-shared';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { PrivateMessage, QuickReply } from '@ay/bot';
import { delay } from 'bluebird';
import moment from 'moment';
import { EmptyResponseError } from '../../util/empty-response-error';
import { MessageOperationType } from '../pages/customer-service/index/index.component';
import { Group } from '../pages/filter/group';
import { Rule } from '../pages/filter/rule/rule';
import { CouponService } from '../service/coupon.service';
import { PageService } from '../service/page.service';
import { Affecter } from './affected.component';
import { Message } from './base/base.message';
import { EditTextComponent } from './edit-text/edit-text.component';
import { MessageToolbar } from './factory/message-toolbar';
import { MessageFactoryService } from './message-factory.service';
import { ReplyTextComponent } from './reply-text/reply-text.component';
import { SubmenuComponent } from './submenu/submenu.component';
import { TextComponent } from './text/text.component';

export interface MessageFactoryDependentService {
  matConnectedDialog: MatConnectedDialog;
  couponService: CouponService;
  pageService?: PageService;
}

export type Origin =
  | 'private-message'
  | 'flow'
  | 'broadcast'
  | 'promotion-channel'
  | 'embedded-private-message'
  | 'preview'
  | 'notification'
  | 'ticket-message'
  | 'customer-service'
  | 'canned-message';

@Injectable()
export class Package extends Affecter {
  public enabledActions: {
    /** 文字訊息（在 LIFF 分享訊息時不能用） */
    message: boolean;
    /** 連結 */
    uri: boolean;
    /** 下一個節點，只有在流程可以用 */
    next: boolean;
    /** 分享訊息 */
    shareMessageThroughPromotion: boolean;
    /** 透過推廣通路加入好友 */
    addThroughPromotion: boolean;
    /** 時間日期選擇器 */
    dateTimePicker: boolean;
    /** 回呼函數 */
    postback: boolean;
  } = {
    message: true,
    uri: true,
    next: true,
    shareMessageThroughPromotion: true,
    addThroughPromotion: true,
    dateTimePicker: true,
    postback: true,
  };
  public enableQuickReplies = true;
  public enableEditText = false;
  public enableReplyText = false;

  public isLoading: boolean = false;
  public oriMessages: Message[] = [];
  public messages: Message[] = [];
  public submenu: SubmenuComponent;
  public quickReplies: QuickReply.Any[] = [];
  public inTextEditMode: boolean = false;
  public name: string =
    moment().format('YYYY-MM-DD HH:mm') + $localize` 建立的訊息包`;
  public id: number;
  public tags: TagDto[] = [];
  public rules: Rule[] = [];
  public reserved: Date;
  public status: PackageStatus;
  public source: PackageFetchForBroadcast | PackageFetchForSendNode;

  public reservation: Date;

  public toolbars: MessageToolbar[] = [];

  public messageOperationType: MessageOperationType;

  public currentEditText = '';

  public constructor(
    private readonly _messageFactoryService: MessageFactoryService,
    @Inject('ORIGIN') public origin: Origin,
  ) {
    super();
  }

  public add(message: Message) {
    this.messages.push(message);
    this.changed();
  }

  public remove(index: number) {
    this.messages.splice(index, 1);
    this.changed();
  }

  public removeAll() {
    this.messages = [];
    this.changed();
  }

  public async insert(messages$: Promise<Message | Message[]>) {
    let messages = await messages$;

    if (!Array.isArray(messages)) {
      messages = [messages];
    }

    messages.filter(Boolean).forEach((message) => {
      try {
        this.add(message);
      } catch (error) {
        if (error instanceof EmptyResponseError) return;
        throw error;
      }
    });

    this.focusFirstMessage();
  }

  public async swap(origin: number, target: number) {
    await delay(1);
    let tmp = this.messages[origin];
    this.messages[origin] = this.messages[target];
    this.messages[target] = tmp;
    this.changed();
  }

  public get group() {
    const group = new Group('AND', this.name);
    group.rules = this.rules;
    return group;
  }

  public toSaveForSendNode(): PackageSaveForSendNode {
    const records = this.messages.map((message, order) => {
      message.content.order = order;
      return message.toJSON();
    });

    const tagIds = this.tags.map((tag) => tag.id);
    const filter = this.group.toRuleObject();

    const data = {
      id: this.id,
      name: this.name,
      records,
      filter,
      tagIds,
      quickReplies: this.quickReplies,
    };

    return data;
  }

  public checkModify() {
    return this.oriMessages.length !== this.messages.length;
  }

  public toSaveForBroadcast(): PackageSaveForBroadcast {
    return {
      id: this.id === -1 ? undefined : this.id,
      name: this.name,
      records: this.messages.map((message) => message.toJSON()),
      tagIds: this.tags.map((tag) => tag.id),
      quickReplies: this.quickReplies,
      condition: this.group.toGroupObject(),
      reservation: this.reservation,
    };
  }

  public toSaveForPromotionChannel(): PackageSaveForPromotionChannel {
    return {
      id: this.id === -1 ? undefined : this.id,
      records: this.messages.map((message) => message.toJSON()),
    };
  }

  public isEmpty() {
    return (
      this.messages.length === 0 ||
      (this.messages.length === 1 &&
        this.messages[0].type === 'text' &&
        this.messages[0].content['content'] === '')
    );
  }

  public checkError = () => null;
  public validate = () => {
    return { valid: true, errors: [] };
  };

  public focusFirstMessage() {
    if (!this.messages.length) return;
    setTimeout(() => {
      const message = this.messages[0];
      message.affectedComponents.forEach((component) => {
        if (
          !(component instanceof TextComponent) &&
          !(component instanceof EditTextComponent) &&
          !(component instanceof ReplyTextComponent)
        ) {
          return;
        }
        component.textarea.nativeElement.focus();
      });
    }, 500);
  }

  public async loadHistory(messages: PrivateMessage[]) {
    const exists = this.messages;

    const existRecordIds = exists.map((message) => message.logId);

    const unique = messages.filter(
      (message) => !existRecordIds.includes(message.logId),
    );

    for (const raw of unique) {
      try {
        const message =
          await this._messageFactoryService.createFromPrivateMessage(raw);
        if (!message) return;
        exists.push(message);
        message.logId = raw.logId;
      } catch (error) {
        console.error(error, raw);
      }
    }

    this.messages = exists.sort(
      (a, b) => a.createdAt.getTime() - b.createdAt.getTime(),
    );

    this.changed();
  }

  public removeFirstIfEmpty() {
    if (this.messages.length === 0) return;
    if (this.messages[0].type !== 'text') return;
    if (this.messages[0].content['content'] !== '') return;
    this.messages.shift();
  }
}
