import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'switchType',
    pure: true,
    standalone: true,
})
export class SwitchTypePipe implements PipeTransform {
  private _typeMap = {
    TEXT: $localize`文字比對`,
    KEYWORD: $localize`關鍵字比對`,
    DATETIME: $localize`日期/時間比對`,
    HAS_TAG: $localize`檢測標籤`,
    HAS_PROPERTY: $localize`檢測屬性`,
    FILTER_POST: $localize`篩選 Facebook 文章`,
    FILTER_IG_POST: $localize`篩選 Instagram 文章`,
    FILTER_POST_HASHTAG: $localize`比對 Facebook 文章 HashTag`,
    SOURCE_CODE: $localize`程式碼`,
    FILTER_BOT: $localize`篩選機器人`,
    FILTER_PLATFORM: $localize`篩選平台`,
    FILTER_PROFILE_TYPE: $localize`篩選好友類型`,
    FILTER_PROFILE_ID: $localize`篩選好友編號`,
    CHECK_EXCHANGE: $localize`檢測是否可以兌換`,
    LINGTELLI: $localize`語意分析`,
    BEACON_ID: $localize`Beacon ID`,
    ELSE: $localize`其他情況`,
  };
  public transform(input: string): string {
    let res = this._typeMap[input];
    if (res) return res;
    return input;
  }
}
