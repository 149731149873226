import { PackageModel } from '@ay-gosu/server-shared';
import { Card, QuickReply } from '@ay/bot';
import * as uuid from 'uuid';
import { Package } from '../../../../message/package.class';
import { Node } from '../class';
import { CardsMessage } from '../../../../message/cards/cards.message';
import { GosuValidate } from '../../../../components/gosu-validator/gosu-validator.class';
import { Message } from '../../../../message/base/base.message';
import { PosterMessage } from '../../../../message/poster/poster.message';
import { ImageMessage } from '../../../../message/image/image.message';
import { TextMessage } from '../../../../message/text/text.message';
export type QuickReplyType =
  | 'email'
  | 'phone'
  | 'date'
  | 'camera'
  | 'album'
  | 'postback'
  | 'next'
  | 'message'
  | 'location';

export type DateMode = 'datetime' | 'date' | 'time';

export class SendNode extends Node {
  public icon = 'textsms';
  public packageId: number;
  public profileIds: string;
  public fromOldPackage: number;

  public junctions: { data: string; text: string }[] = [];
  public quickReplies: QuickReply.Any[] = [];
  public recordChanged = false;
  public isAsync = false;
  public useStacker = false;
  public stackerName = '';
  public cardMergeLimit = 10;
  public readonly extraProperties = [
    'junctions',
    'packageId',
    'profileIds',
    'fromOldPackage',
    'stackerName',
    'cardMergeLimit',
    'quickReplies',
    'isAsync',
    'processed',
  ];
  private _package: Package;

  public get package() {
    return this._package;
  }

  public set package(value: Package) {
    this._package = value;
    this._setCheckErrorToPkg();
  }

  public processed = true;

  public async saveRecords() {
    if (this.fromOldPackage) {
      return;
    }

    try {
      const data = this.package.toSaveForSendNode();
      data.name = this.name;
      // 由於 Share 機制會快取相同的請求，所以如果PackageData的內容都一樣，可能造成發送訊息節點的編號重複。導致內容連動。因此加上一個亂數用來區別所有的訊息內容
      data['__no'] = uuid.v4();
      const packageId = await PackageModel.saveForSendNode(data);
      this.packageId = packageId;
    } catch (error) {
      console.error(error);
    }
  }

  public checkModify() {
    this.isModify =
      (super.checkModify() || this.package?.checkModify()) ?? false;
    return this.isModify;
  }

  public async reset() {
    this.package = await this.flowService.messageFactory.createForFlow();
    this.error = null;
  }

  private _setCheckErrorToPkg() {
    if (this.package) this.package.checkError = this.checkError.bind(this);
    if (this.package) this.package.validate = this.validate.bind(this);
  }

  public checkError(): boolean {
    return !this.validate().valid;
  }

  public validate(): { valid: boolean; errors: any[] } {
    this.hasError = false;
    const isCheckError = window.localStorage.getItem('isDebug') === 'true';
    const errors: any[] = [];
    if (isCheckError) {
      if (!this.package) {
        this.hasError = true;
        return { valid: false, errors: [] };
      }

      if (this.package.messages.length === 0) {
        this.hasError = true;
        return {
          valid: false,
          errors: [{ path: ['messages'] }],
        };
      }

      this.package.messages.forEach((message, index) => {
        if (this._checkError(message)) {
          this.hasError = true;
          errors.push({ path: ['messages', index] });
        }
      });

      this.package.quickReplies.forEach((quickReply, index) => {
        if (
          (quickReply.type === 'next' || quickReply.type === 'message') &&
          (!quickReply.text || !quickReply.label)
        ) {
          this.hasError = true;
          errors.push({ path: ['quickReplies', index] });
        }
      });
    }
    return { valid: !this.hasError, errors };
  }

  private _checkError = (message: Message) => {
    let hasError = false;
    if (message instanceof TextMessage) {
      if (GosuValidate.required(message.content.content)) {
        hasError = true;
      }
    }
    if (message instanceof CardsMessage) {
      message.content.cards.forEach((card) => {
        if (!(card instanceof Card)) {
          return;
        }
        if (GosuValidate.required(card.title)) {
          hasError = true;
        }
        if (GosuValidate.required(card.content)) {
          hasError = true;
        }
        card.buttons.forEach((button) => {
          if (GosuValidate.required(button.label)) {
            hasError = true;
          }
          if (button.type === 'message' && GosuValidate.required(button.text)) {
            hasError = true;
          }
          if (button.type === 'next' && GosuValidate.required(button.text)) {
            hasError = true;
          }
          if (button.type === 'uri' && GosuValidate.required(button.uri)) {
            hasError = true;
          }
        });
      });
    }
    if (message instanceof PosterMessage) {
      if (message.templateLost) {
        hasError = true;
      }
    }
    if (message instanceof ImageMessage) {
      if (message.templateLost) {
        hasError = true;
      }
    }
    return hasError;
  };
}
