<div fxLayout="row wrap" [ngSwitch]="command.item">
  <div class="no">{{ no }}.</div>

  <mat-form-field class="item" legacyAppearance>
    <mat-label i18n>類型</mat-label>
    <mat-select [(ngModel)]="command.item" (ngModelChange)="afterItemChange()">
      <mat-option value="tag" *ngIf="target === 'profile'" i18n
        >標籤</mat-option
      >
      <mat-option value="property" i18n>屬性</mat-option>
    </mat-select>
  </mat-form-field>

  <ng-template [ngSwitchCase]="'tag'">
    <mat-form-field class="action" legacyAppearance>
      <mat-label i18n>動作</mat-label>
      <mat-select [(ngModel)]="command.action">
        <mat-option value="add" i18n>貼上</mat-option>
        <mat-option value="remove" i18n>撕下</mat-option>
      </mat-select>
    </mat-form-field>

    <programmable [(value)]="command.tagIds" dataType="string[]" fxFlex="1 0 0">
      <mat-form-field legacyAppearance>
        <mat-label i18n>標籤</mat-label>
        <ng-container *ngIf="command.action === 'add'">
          <gosu-tag-tree-picker
            fxFlex="1 0 0"
            [target]="$any(node.target)"
            [(value)]="command.tagIds"
            title="貼上標籤"
            i18n-title="貼上標籤"
          >
          </gosu-tag-tree-picker>
        </ng-container>
        <ng-container *ngIf="command.action === 'remove'">
          <gosu-tag-tree-picker
            fxFlex="1 0 0"
            [target]="$any(node.target)"
            [(value)]="command.tagIds"
            title="撕下標籤"
            i18n-title="撕下標籤"
          >
          </gosu-tag-tree-picker>
        </ng-container>
        <gosu-validator
          [(ngModel)]="command.tagIds"
          [rules]="{ required: true }"
          [offsetY]="20"
        ></gosu-validator>
        @if (deletedTagIds && deletedTagIds.length > 0) {
          <gosu-validator
            [(ngModel)]="command.tagIds"
            [rules]="{ bannedOptions: true }"
            [offsetX]="3"
            [offsetY]="20"
            [message]="noneExistTag"
            [payload]="{ bannedOptions: deletedTagIds }"
          ></gosu-validator>
        }
      </mat-form-field>
    </programmable>
  </ng-template>

  <ng-template [ngSwitchCase]="'property'">
    <mat-form-field fxFlex="1 0 0" legacyAppearance>
      <mat-label i18n>屬性</mat-label>
      <mat-select
        #propSelector
        [(ngModel)]="command.key"
        (ngModelChange)="afterPropertyChange()"
        (openedChange)="keyword$.next('')"
      >
        <mat-select-trigger [attr.data-key]="command.key">
          {{ selected(propSelector.selected) }}
        </mat-select-trigger>

        <mat-form-field appearance="fill" class="search" legacyAppearance>
          <input
            matInput
            placeholder="屬性搜尋"
            i18n-placeholder="屬性搜尋"
            [ngModel]="keyword$.value"
            (ngModelChange)="keyword$.next($event)"
            autocomplete="off"
          />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
        <mat-option
          *ngFor="let config of configs$ | async"
          [value]="config.key"
          [attr.data-key]="config.key"
          [disabled]="seletedPropertyConfigs.includes(config.key)"
        >
          {{ config.name }}
        </mat-option>
      </mat-select>
      <gosu-validator
        [(ngModel)]="command.key"
        [rules]="{ required: true }"
        [offsetY]="20"
      ></gosu-validator>
      <gosu-validator
        [(ngModel)]="command.key"
        [rules]="{ bannedOptions: true }"
        [offsetY]="20"
        [payload]="{ bannedOptions: duplicateProperties }"
        [message]="duplicatePropertiesText"
      ></gosu-validator>
    </mat-form-field>

    <mat-form-field *ngIf="config" class="action" legacyAppearance>
      <mat-label i18n>操作</mat-label>
      <mat-select [(ngModel)]="command.action">
        <mat-option value="set" i18n>設定為</mat-option>

        <ng-container *ngIf="config.dataType === 'number'">
          <mat-option value="plus" i18n>加上</mat-option>
          <mat-option value="minus" i18n>減去</mat-option>
        </ng-container>

        <ng-container *ngIf="config.dataType === 'list'">
          <mat-option value="add" i18n>加入項目</mat-option>
          <mat-option value="remove" i18n>刪除項目</mat-option>
        </ng-container>

        <mat-option value="clear" i18n>清空</mat-option>
      </mat-select>
    </mat-form-field>

    <programmable
      fxFlex="1 0 0"
      [(value)]="command.value"
      dataType="string"
      [class.list]="config.dataType === 'list'"
      *ngIf="config && command.action !== 'clear'"
    >
      <mat-form-field legacyAppearance>
        <mat-label i18n>值</mat-label>

        <input
          *ngIf="config.dataType !== 'list'; else list"
          matInput
          [type]="config.dataType === 'number' ? 'number' : 'text'"
          [(ngModel)]="command.value"
        />

        <ng-template #list>
          <mat-chip-grid #chipList>
            <mat-chip-row
              *ngFor="let item of command.value | split"
              removable="true"
              (removed)="removeText(item)"
            >
              {{ item }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip-row>
            <input
              placeholder="值"
              i18n-placeholder="值"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addText($event)"
            />
          </mat-chip-grid>
        </ng-template>

        <gosu-validator
          [(ngModel)]="command.value"
          [rules]="{ required: true }"
          [offsetY]="20"
        ></gosu-validator>
      </mat-form-field>
    </programmable>

    <div *ngIf="config && config.enableLogger">
      <mat-form-field legacyAppearance>
        <mat-label i18n>異動原因</mat-label>
        <input matInput [(ngModel)]="command.reason" />
      </mat-form-field>
    </div>
    <div
      class="empty-space"
      fxFlex="1 0 0"
      *ngIf="command.action === 'clear'"
    ></div>
  </ng-template>

  <div class="remove-command">
    <button
      mat-icon-button
      class="remove"
      (click)="delete.next()"
      matTooltip="刪除此設定動作"
    >
      <mat-icon class="mat-18">close</mat-icon>
    </button>
  </div>
</div>
