// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { InteractiveDto } from './interactive.dto';
import { PrizeWheelPrize } from './prize-wheel-prize.dto';

export class PrizeWheelDto extends InteractiveDto {
  public type = 'PRIZE_WHEEL' as const;

  // 活動獎品包
  public ticketEventId?: number;

  /** 背景圖 */
  public background?: string;

  /** 是否使用預設 背景圖 */
  public isDefaultBackground?: boolean;

  /** 轉輪圖 */
  public wheelImage?: string;

  /** 是否使用預設 轉輪圖 */
  public isDefaultWheelImage?: boolean;

  /** 靶心指針按鈕圖 */
  public arrowImage?: string;

  /** 是否使用預設 靶心指針按鈕圖 */
  public isDefaultArrowImage?: boolean;

  /* 轉盤底圖 */
  public machineImage?: string;

  /* 是否使用預設 轉盤底圖 */
  public isDefaultMachineImage?: boolean;

  /* 未中獎標題 */
  public loseTitle?: string;

  /* 未中獎內容 */
  public loseDescription?: string;

  /* 未中獎圖片 */
  public loseImage?: string;

  /* 是否使用預設未中獎圖片 */
  public isDefaultLoseImage?: boolean;

  /** 獎項 */
  public prizes?: PrizeWheelPrize[];
}
// 92bf1628922f5e3cefd11ab74315d56a27d149307eadefdb1db359d738789071
